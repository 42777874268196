import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '../../../frameworks/redux';
import {
  Locale,
  Permission,
  BillingPeriod,
  SubscriptionStatus,
  SubscriptionType,
  RoleType,
} from '@repo/shared/enums';
import {
  IAccount,
  IAppConfiguration,
  ICompany,
  ICurrentUser,
  ICurrentUserPermissions,
  IDataImportSettings,
} from '@repo/shared/types';
import { accountEntityAdapter } from '@store/entityAdapters';
import { getAuditObjectName } from '@repo/shared/utils/auditObjects';

const getCurrentUser = (state: IRootState): ICurrentUser => state.account.user;
const getCoupon = (state: IRootState) =>
  state.account.subscription.data?.automatedSubscriptionData?.coupon ||
  state.account.subscription.data?.coupon ||
  null;
const getBillingPeriod = (state: IRootState) =>
  state.account.subscription.data?.automatedSubscriptionData
    ? state.account.subscription.data?.automatedSubscriptionData.billingPeriod
    : state.account.payment.billingPeriod;
const getPermissionsMap = (state: IRootState): ICurrentUserPermissions =>
  state.account.user?.role?.permissions;
const hasPermission = createSelector(
  [
    getPermissionsMap,
    (_: IRootState, rolePermissionTypes: Permission | Permission[]) =>
      rolePermissionTypes,
  ],
  (permissions, rolePermissionTypes) => {
    if (Array.isArray(rolePermissionTypes)) {
      return rolePermissionTypes.map((type) => permissions?.[type] || false);
    }

    return permissions?.[rolePermissionTypes] || false;
  }
);
const getCompany = (state: IRootState) => state.account.company;
const getAccounts = createSelector(
  (state: IRootState) => state.account.accounts,
  ({ loading, data, error }) => ({
    loading,
    error,
    data: accountEntityAdapter.getSelectors().selectAll(data),
  })
);

export const accountSelectors = {
  getCurrentUser,
  getAuthUserId: (state: IRootState): string => state.account.authUserId,
  getCompany,
  getPermissionsMap,
  makeHasPermission: () => hasPermission,
  hasPermission,
  getObjectName: createSelector(
    [
      (state: IRootState) => state.account.company,
      (state: IRootState) => state.account.locale,
    ],
    (company: ICompany, locale: Locale) => {
      const { usagePurpose, usagePurposeObjectName } = company;

      return getAuditObjectName({
        usagePurpose,
        usagePurposeObjectName,
        locale,
      });
    }
  ),
  getCurrentCompanyId: (state: IRootState) => state.account.company?.id,
  isFullScreenLoaderVisible: (state: IRootState) =>
    state.account.showFullScreenLoader,
  getPayWallState: createSelector(
    [
      (state: IRootState) => state.account.user,
      (state: IRootState) => state.account.company,
    ],
    (user, company) => {
      if (user === null || company === null) {
        return {
          show: false,
          isTrial: false,
        };
      }

      return {
        show: company.subscriptionStatus === SubscriptionStatus.Blocked,
        isTrial: company.subscriptionType === SubscriptionType.Trial,
      };
    }
  ),
  getAuthFormLoading: (state: IRootState) => state.account.authFormLoading,
  getLocale: (state: IRootState): Locale => state.account.locale,
  getPaymentStep: (state: IRootState) => state.account.payment.step,
  getBillingPeriod,
  getSubscription: (state: IRootState) => state.account.subscription,
  getCoupon,
  getTotalCost: createSelector(
    [
      (state: IRootState) => state.account.prices.data,
      getBillingPeriod,
      getCoupon,
    ],
    (prices, billingPeriod, coupon) => {
      if (!prices) {
        return {
          totalCost: 0,
          couponDiscount: 0,
        };
      }

      const {
        observersAnnualPrice,
        observersMonthlyPrice,
        observersQuantity,
        usersAnnualPrice,
        usersMonthlyPrice,
        usersQuantity,
      } = prices;

      const totalAnnualCost =
        observersAnnualPrice * observersQuantity +
        usersAnnualPrice * usersQuantity;
      const totalMonthlyCost =
        observersMonthlyPrice * observersQuantity +
        usersMonthlyPrice * usersQuantity;

      const totalCost =
        billingPeriod === BillingPeriod.Annually
          ? totalAnnualCost
          : totalMonthlyCost;

      let couponDiscount = 0;

      if (coupon) {
        if (coupon.percentage !== null) {
          couponDiscount = totalCost * (coupon.percentage / 100);
        } else if (coupon.amountInCents !== null) {
          couponDiscount = coupon.amountInCents / 100;
        }
      }

      return {
        observersQuantity,
        usersQuantity,
        observersCost:
          billingPeriod === BillingPeriod.Annually
            ? observersQuantity * observersAnnualPrice
            : observersQuantity * observersMonthlyPrice,
        usersCost:
          billingPeriod === BillingPeriod.Annually
            ? usersQuantity * usersAnnualPrice
            : usersQuantity * usersMonthlyPrice,
        totalCost,
        couponDiscount: couponDiscount || 0,
      };
    }
  ),
  getPrices: createSelector(
    (state: IRootState) => state.account.prices,
    (prices) => prices
  ),
  getInvoices: createSelector(
    (state: IRootState) => state.account.invoices,
    (invoices) => invoices
  ),
  getAccounts,
  getCurrentAccount: createSelector(
    [getCompany, getAccounts],
    (company: ICompany | null, accounts: { data: IAccount[] }) => {
      return (
        accounts.data.find((account) => account.company.id === company?.id) ||
        null
      );
    }
  ),
  isCreateNewCompanyModalOpened: (state: IRootState) =>
    state.account.showCreateNewCompanyModal,
  isSetupCompanyModalOpened: (state: IRootState) =>
    state.account.showSetupCompanyModal,
  getConfirmDeleteAccountModalState: createSelector(
    (state: IRootState) => state.account.confirmDeleteAccountModal,
    (state) => state
  ),
  getSampleDataModal: (state: IRootState) => state.account.sampleDataModal,
  isWelcomeModalVisible: (state: IRootState) => state.account.showWelcomeModal,
  isDownloadAppsModalVisible: (state: IRootState) =>
    state.account.showDownloadAppsModal,
  getSelfGuidedTour: (state: IRootState) => state.account.selfGuidedTour,
  getSelfGuidedTourLoading: (state: IRootState) =>
    state.account.selfGuidedTour.loading,
  getSelfGuidedTourStep: (state: IRootState) =>
    state.account.selfGuidedTour.step,
  getAuthProvider: (state: IRootState) => state.account.authProvider,
  getBrowserTabId: (state: IRootState) => state.account.browserTabId,
  isAdmin: createSelector(
    getCurrentUser,
    (currentUser) => currentUser?.role?.roleType === RoleType.Admin
  ),
  getDataImportSettings: createSelector(
    getCompany,
    (company: ICompany | null): IDataImportSettings =>
      company?.dataImportSettings || {
        isLocationsReadonly: false,
        isUsersReadonly: false,
        isUsersGroupsReadonly: false,
        isJobTitlesAndAccessReadonly: false,
      }
  ),
  getLiveChatVisibility: (state: IRootState) =>
    state.account.liveChatVisibility,
  isFormsDigitizationModalVisible: (state: IRootState) =>
    state.account.showFormsDigitizationModal,
  getAppConfiguration: (state: IRootState): IAppConfiguration =>
    state.account.appConfiguration,
  isNewVersionReleaseModalOpened: (state: IRootState): boolean =>
    state.account.showNewVersionReleasedModal,
};
