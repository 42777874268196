import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { FormInstance } from 'antd/es/form';

import { useAppDispatch } from '@hooks';
import { accountActions, accountSelectors } from '@store';
import { notification } from '@utils';

import Form from '@components/shared/ant/Form';
import CompanyForm from '@components/account/CompanyForm';

interface IProps {
  children: ({
    loading,
    form,
  }: {
    loading: boolean;
    form: FormInstance;
  }) => React.ReactNode;
  onSubmit?: () => void;
}

const UpdateCompanyForm: React.FC<IProps> = ({ children, onSubmit }) => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const company = useSelector(accountSelectors.getCompany);

  const [loading, setLoading] = useState(false);

  return (
    <>
      <CompanyForm
        form={form}
        initialValues={company}
        onSubmit={async (values) => {
          setLoading(true);

          const resultAction = await dispatch(
            accountActions.updateCompany({
              update: {
                ...values,
              },
            })
          );

          if (accountActions.updateCompany.fulfilled.match(resultAction)) {
            if (onSubmit) {
              onSubmit();
            }

            notification.success({
              message: formatMessage({
                id: 'CompanyInformationHasBeenSuccessfullyUpdated',
              }),
            });
          } else {
            notification.error({
              message: formatMessage({ id: 'ErrorWhileUpdatingCompany' }),
              description: resultAction.payload,
            });
          }

          setLoading(false);
        }}
      />
      {children({ loading, form })}
    </>
  );
};

export default UpdateCompanyForm;
