import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import {
  AuthLogo,
  Container,
  Desc,
  Feature,
  Features,
  Headline,
  Highlights,
  FormContainer,
  Title,
  SignUpSubmitBtn,
  FooterButton,
} from '../styled';
import { accountActions } from '@store';
import { e2eTestElements, routes } from '@config';
import { useAppDispatch } from '@hooks';
import { SignUpStep } from '@repo/shared/enums';

import CreateAccountForm from './CreateAccountForm';
import CreateNewCompanyForm from './CreateNewCompanyForm';
import PreSelectedTemplate from '@components/account/AuthForms/SignUp/PreSelectedTemplate/PreSelectedTemplate';

const SignUp: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const toCompanySetup = location.state?.toCompanySetup || false;
  const [searchParams] = useSearchParams();

  const [templateId, setTemplateId] = useState<string | null>(null);
  const [step, setStep] = useState<SignUpStep>(SignUpStep.CreateAccount);

  const templateIdParam = searchParams.get('templateId');

  useEffect(() => {
    if (templateIdParam) {
      setTemplateId(templateIdParam);
    }
  }, [templateIdParam]);

  useEffect(() => {
    if (toCompanySetup && step === SignUpStep.CreateAccount) {
      setStep(SignUpStep.SetupCompany);
    }
  }, [step, toCompanySetup]);

  return (
    <Container>
      <Highlights>
        <AuthLogo />
        <Features>
          <Feature>
            <Headline>{formatMessage({ id: 'GetStartedFree' })}</Headline>
            <Desc>
              {formatMessage({
                id: 'StartDoingMobileInspectionsElectronically',
              })}
            </Desc>
          </Feature>
          <Feature>
            <Headline>{formatMessage({ id: 'ConciergeOnboarding' })}</Headline>
            <Desc>
              {formatMessage({
                id: 'EnjoyComplimentaryWhiteGloveOnboardingService',
              })}
            </Desc>
          </Feature>
          <Feature>
            <Headline>{formatMessage({ id: 'SmartAuditBuilder' })}</Headline>
            <Desc>
              {formatMessage({ id: 'GetThePowerOfTheSmartestAuditBuilder' })}
            </Desc>
          </Feature>
          <Feature>
            <Headline>
              {formatMessage({ id: 'OperationalExcellence' })}
            </Headline>
            <Desc>
              {formatMessage({
                id: 'GainValuableInsightsToImproveCorrectiveAndPreventativeActions',
              })}
            </Desc>
          </Feature>
        </Features>
      </Highlights>
      <FormContainer hasFooter>
        {step === SignUpStep.CreateAccount ? (
          <CreateAccountForm />
        ) : (
          <>
            <Title level={4}>{formatMessage({ id: 'SetupCompany' })}</Title>
            {templateId && (
              <PreSelectedTemplate
                templateId={templateId}
                onError={() => {
                  setTemplateId(null);
                }}
              />
            )}
            <CreateNewCompanyForm templateId={templateId}>
              {({ form, loading }) => (
                <>
                  <SignUpSubmitBtn
                    type="primary"
                    loading={loading}
                    style={{ margin: '6px 0 24px' }}
                    data-e2e={e2eTestElements.account.companyForm.submitBtn}
                    onClick={() => {
                      form.submit();
                    }}
                  >
                    {formatMessage({
                      id: 'Next',
                    })}
                  </SignUpSubmitBtn>
                  <FooterButton
                    type="button"
                    onClick={() => {
                      dispatch(accountActions.signOut());
                      navigate(routes.signin);
                    }}
                  >
                    {formatMessage({
                      id: 'LogOut',
                    })}
                  </FooterButton>
                </>
              )}
            </CreateNewCompanyForm>
          </>
        )}
      </FormContainer>
    </Container>
  );
};

export default SignUp;
