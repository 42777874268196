import React from 'react';
import { useIntl } from 'react-intl';

import { accountActions } from '@store';
import { useAppDispatch } from '@hooks';

import PageHeader from '../../shared/ant/PageHeader';
import Button from '../../shared/ant/Button';

const AccountsHeader: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  return (
    <>
      <PageHeader
        title={formatMessage({
          id: 'Accounts',
        })}
        extra={
          <Button
            type="primary"
            onClick={() => {
              dispatch(accountActions.toggleCreateNewCompanyModal(true));
            }}
          >
            {formatMessage({
              id: 'CreateNew',
            })}
          </Button>
        }
      />
    </>
  );
};

export default AccountsHeader;
