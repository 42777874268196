export const routes = {
  signin: '/signin',
  signUpCallback: '/signup-callback',
  signUp: '/signup',
  adminSignIn: '/secret-signin',
  changePassword: '/change-password',
  resetPassword: '/reset-password',
  setNewPassword: '/set-new-password/:userAuthId',
  dashboard: '/',
  setup: '/setup',
  roles: '/settings/roles',
  accounts: '/settings/accounts',
  users: {
    list: '/users',
    user: '/users/:id',
    userDetails: '/users/:id/details',
    userGroups: '/users/:id/groups',
    userAccess: '/users/:id/access',
    groupsList: '/users/groups',
    groupDetails: '/users/groups/:id',
    groupAssignments: '/users/groups/:id/assignments',
    access: '/users/access',
    jobTitles: '/users/job-titles',
  },
  tags: '/settings/tags',
  tagsAudits: '/settings/tags/audits',
  tagsItems: '/settings/tags/items',
  tagsActions: '/settings/tags/corrective-actions',
  notifications: '/settings/notifications',
  integration: '/settings/integration',
  profile: '/profile',
  scores: '/settings/scores',
  auditObjects: {
    list: '/audit-objects',
    auditObject: '/audit-objects/:id',
    auditObjectDetails: '/audit-objects/:id/details',
    auditObjectAttributes: '/audit-objects/:id/attributes',
    auditObjectGroups: '/audit-objects/:id/groups',
    auditObjectAccess: '/audit-objects/:id/access',
    auditObjectQRCodes: '/audit-objects/:id/qr-codes',
    groupDetails: '/audit-objects/groups/:id',
    groupAssignments: '/audit-objects/groups/:id/assignments',
    groupQRCodes: '/audit-objects/groups/:id/qr-codes',
    groupsList: '/audit-objects/groups',
    attributesList: '/audit-objects/attributes',
    attributeDetails: '/audit-objects/attributes/:id',
    attributeAssignments: '/audit-objects/attributes/:id/assignments',
  },
  notFound: '/not-found',
  schedulesLegacy: '/audits/scheduled',
  schedules: '/audits/schedules',
  scheduleDetails: '/audits/schedules/:id',
  advancedSchedules: '/audits/advanced-schedules',
  advancedScheduleDetails: '/audits/advanced-schedules/:id',
  advancedSchedulePeriodDetails:
    '/audits/advanced-schedules/:schedulePlanId/history/:schedulePlanPeriodId',
  library: '/library',
  publicAuditReport: '/audit-report/:id',
  auditTemplates: '/library/audit-templates',
  builderDraft: '/library/audit-templates/draft/:id',
  builderPublished: '/library/audit-templates/:id',
  builderNewDraft: '/library/audit-templates/draft/new',
  actionTemplates: '/library/action-templates',
  correctiveActions: '/audits/actions',
  myActions: '/audits/actions/my',
  myAction: '/audits/actions/my/:id',
  pendingActions: '/audits/actions/pending',
  pendingAction: '/audits/actions/pending/:id',
  completedActions: '/audits/actions/completed',
  completedAction: '/audits/actions/completed/:id',
  expiredActions: '/audits/actions/expired',
  expiredAction: '/audits/actions/expired/:id',
  actionDetails: '/audits/action/:id',
  audits: '/audits',
  myAudits: '/audits/my',
  myAudit: '/audits/my/:id',
  pendingAudits: '/audits/pending',
  pendingAudit: '/audits/pending/:id',
  completedAudits: '/audits/archive',
  completedAudit: '/audits/archive/:id',
  auditReport: '/audits/archive/:id/report',
  expiredAudits: '/audits/expired',
  expiredAudit: '/audits/expired/:id',
  reports: '/reports',
  summaryReport: '/reports/summary',
  auditPerformanceReport: '/reports/audit-performance-analysis',
  customReport: '/reports/custom',
  itemAnalysisReport: '/reports/item-analysis',
  auditorPerformanceReport: '/reports/auditor-performance-analysis',
  auditorPerformanceReportRealTime:
    '/reports/auditor-performance-analysis/real-time',
  auditorPerformanceReportHistorical:
    '/reports/auditor-performance-analysis/historical',
  auditObjectPerformanceReport: '/reports/audit-object-performance-analysis',
  auditObjectPerformanceReportRealTime:
    '/reports/audit-object-performance-analysis/real-time',
  auditObjectPerformanceReportHistorical:
    '/reports/audit-object-performance-analysis/historical',
  settings: '/settings',
  generalSettings: '/settings/general',
  billing: '/settings/billing',
  subscription: '/settings/billing/subscription',
  payment: '/settings/billing/subscription/pay',
  invoices: '/settings/billing/invoices',
  issueTypes: '/issues/types',
  issueTypeDetails: '/issues/types/:id',
  issueTypeQrCodes: '/issues/types/:id/qr-codes',
  createIssueType: '/issues/types/create',
  issues: '/issues',
  myIssues: '/issues/my',
  myIssue: '/issues/my/:id',
  pendingIssues: '/issues/pending',
  pendingIssue: '/issues/pending/:id',
  archiveIssues: '/issues/archive',
  archiveIssue: '/issues/archive/:id',
  issueDetails: '/issue/:id',
  createIssue: '/create-issue',
  createIssueError: '/create-issue/error',
  issuesQrCodes: '/issues/qr-codes',
};
