import styled from 'styled-components';
import Typography from 'antd/es/typography';

import { colors } from '@repo/shared/config';

const { Title: AntTitle } = Typography;

export const Container = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const Title = styled(AntTitle)`
  margin-bottom: 16px !important;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Main = styled.main`
  flex-grow: 1;
  background: ${colors.white};
  width: 360px;
  margin: 140px auto 30px;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  margin: 30px -4px 0;

  & > * {
    width: 50%;
    margin: 0 4px;
  }
`;
