import { useMemo, Suspense, lazy, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Navigate,
  RouteObject,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import dayjs from 'dayjs';

import {
  PerformanceReportType,
  Permission,
  RoleType,
} from '@repo/shared/enums';
import { IRootState } from '@src/core/frameworks/redux';
import { accountSelectors } from '@store';
import { routes } from '@config';
import { Logger } from '@repo/shared/services';
import { UnexpectedError } from '@repo/shared/errors';
import { AmbassadorParams } from '@repo/shared/types';

import NavigateWithId from '@components/account/AppRoutes/NavigateWithId';
import NavyBlueContainer from '@components/shared/NavyBlueContainer/NavyBlueContainer';
import ChangeDefaultPassword from '@components/account/AuthForms/ChangeDefaultPassword';
import SignIn from '@components/account/AuthForms/SignIn';
import SetNewPassword from '@components/account/AuthForms/ResetPassword/SetNewPassword';
import NonProtectedRoute from '@components/account/AppRoutes/NonProtectedRoute';
import ResetPassword from '@components/account/AuthForms/ResetPassword/ResetPassword';
import Dashboard from '@components/dashboard/Dashboard';
import ProtectedRoute from '@components/account/AppRoutes/ProtectedRoute';
import ItemsTags from '@components/tags/ItemsTags';
import AuditsTags from '@components/tags/AuditsTags';
import ActionsTags from '@components/tags/ActionsTags';
import PendingAudits from '@presentation/Audits/PendingAudits';
import CompletedAudits from '@presentation/Audits/CompletedAudits';
import ExpiredAudits from '@presentation/Audits/ExpiredAudits';
import PendingActions from '@src/presentation/Actions/PendingActions';
import MyActions from '@src/presentation/Actions/MyActions';
import CompletedActions from '@src/presentation/Actions/CompletedActions';
import ExpiredActions from '@src/presentation/Actions/ExpiredActions';
import Notifications from '@components/settings/Notifications/Notifications';
import EditProfile from '@components/account/EditProfile/EditProfile';
import NotFound from '@components/shared/NotFound/NotFound';
import Users from '@components/users/list/Users';
import AuditObjects from '@components/auditObjects/list/AuditObjects';
import AuditObjectsGroups from '@components/auditObjects/groups/list/AuditObjectsGroups';
import Schedules from '@presentation/Schedules/Schedules';
import SchedulePlans from '@presentation/SchedulePlans/SchedulePlans';
import SchedulePlanDetails from '@presentation/SchedulePlans/SchedulePlanDetails/SchedulePlanDetails';
import Roles from '@components/roles/Roles';
import Scores from '@components/scores/Scores';
import Accounts from '@components/account/Accounts/Accounts';
import Subscription from '@components/account/Billing/Subscription/Subscription';
import Invoices from '@components/account/Billing/Invoices/Invoices';
import Payment from '@components/account/Billing/Payment/Payment';
import GeneralSettings from '@components/settings/General/General';
import Integrations from '@ui/components/settings/Integrations/Integrations';
import AttributeAssignments from '@components/auditObjects/attributes/single/AttributesAssignments/AttributeAssignments';
import Attributes from '@components/auditObjects/attributes/list/Attributes';
import UsersGroups from '@components/users/groups/list/UserGroups';
import JobTitles from '@components/users/jobTitles/JobTitles';
import UserDetails from '@components/users/single/UserDetails/UserDetails';
import UserGroups from '@components/users/single/UserGroups';
import UserAccess from '@components/users/single/UserAccess/UserAccess';
import AuditObjectDetails from '@components/auditObjects/single/AuditObjectDetails/AuditObjectDetails';
import AuditObjectGroups from '@components/auditObjects/single/AuditObjectGroups';
import AuditObjectAccess from '@components/auditObjects/single/AuditObjectAccess';
import AuditObjectAttributes from '@components/auditObjects/single/AuditObjectAttributes';
import AuditObjectGroupDetails from '@components/auditObjects/groups/single/AuditObjectGroupDetails/AuditObjectGroupDetails';
import AuditObjectGroupAssignments from '@components/auditObjects/groups/single/AuditObjectGroupAssignments/AuditObjectGroupAssignments';
import UserGroupAssignments from '@components/users/groups/single/UserGroupAssignments/UserGroupAssignments';
import UserGroupDetails from '@components/users/groups/single/UserGroupDetails/UserGroupDetails';
import AttributeDetails from '@components/auditObjects/attributes/single/AttributeDetails/AttributeDetails';
import PublicAuditReport from '@presentation/Audits/PublicAuditReport/PublicAuditReport';
import IssueTypes from '@src/presentation/IssueTypes/IssueTypes';
import IssueTypeDetails from '@src/presentation/IssueTypes/IssueType/IssueTypeDetails/IssueTypeDetails';
import PendingIssues from '@src/presentation/Issues/PendingIssues';
import ResolvedIssues from '@src/presentation/Issues/ResolvedIssues';
import AuditObjectQrCodes from '@components/auditObjects/single/AuditObjectQrCodes';
import CreateIssuePublicPage from '@src/presentation/Issues/CreateIssuePublicPage/CreateIssuePublicPage';
import IssueTypeQrCodes from '@src/presentation/IssueTypes/IssueTypeQrCodes/IssueTypeQrCodes';
import IssueTypeDetailsQrCodes from '@src/presentation/IssueTypes/IssueType/IssueTypeDetailsQrCodes';
import AuditObjectGroupQrCodes from '@components/auditObjects/groups/single/AuditObjectGroupQrCodes';
import IssueTypeRoot from '@src/presentation/IssueTypes/IssueType/IssueTypeRoot';
import CreateIssuePublicPageError from '@src/presentation/Issues/CreateIssuePublicPage/CreateIssuePublicPageError/CreateIssuePublicPageError';
import Loader from '@repo/shared/components/Loader';
import MyIssues from '@presentation/Issues/MyIssues';
import SignUpCallback from '@components/account/AuthForms/SignUp/SignUpCallback';
import SignUp from '@components/account/AuthForms/SignUp/SignUp';

const AuditTemplates = lazy(
  () => import('@presentation/AuditTemplates/AuditTemplates')
);
const ActionTemplates = lazy(
  () => import('@presentation/ActionTemplates/ActionTemplates')
);
const AuditPerformanceReport = lazy(
  () => import('@components/reports/auditPerformance/AuditPerformanceReport')
);
const CustomReport = lazy(
  () => import('@components/reports/custom/CustomReport')
);
const ItemAnalysisReport = lazy(
  () => import('@components/reports/itemAnalysis/ItemAnalysisReport')
);
const HistoricalPerformanceReport = lazy(
  () => import('@components/reports/performance/HistoricalPerformanceReport')
);
const RealTimePerformanceReport = lazy(
  () => import('@components/reports/performance/RealTimePerformanceReport')
);
const SummaryReport = lazy(
  () => import('@components/reports/summary/SummaryReport')
);
const SchedulePlanPeriodDetails = lazy(
  () =>
    import(
      '@presentation/SchedulePlans/SchedulePlanPeriodDetails/SchedulePlanPeriodDetails'
    )
);

const AuditTemplateBuilder = lazy(
  () => import('@presentation/AuditTemplateBuilder/AuditTemplateBuilder')
);

export function usePermission(rolePermissionType: Permission): boolean;
export function usePermission(rolePermissionType: Permission[]): boolean[];
export function usePermission(rolePermissionTypes: Permission | Permission[]) {
  const hasPermission = useMemo<any>(accountSelectors.makeHasPermission, []);
  return useSelector((state: IRootState) =>
    hasPermission(state, rolePermissionTypes)
  );
}

export function useAppRoutes(): RouteObject[] {
  const location = useLocation();

  const user = useSelector(accountSelectors.getCurrentUser);
  const currentUserRoleType = user?.role?.roleType;
  const isAuditeeOrObserver =
    currentUserRoleType === RoleType.Observer ||
    currentUserRoleType === RoleType.Auditee;
  const isAuditor = currentUserRoleType === RoleType.Auditor;
  const appConfiguration = useSelector(accountSelectors.getAppConfiguration);

  const [
    canDoAudits,
    canAssignAudits,
    canManageAudits,
    canScheduleAudits,
    canViewAuditsResults,
    canManageTemplates,
    canManageBilling,
    canViewCorrectiveActions,
    canViewAnalytics,
    canManageGeneralSetup,
    canDoCorrectiveActions,
    canViewIssues,
    canManageIssueTypes,
  ] = usePermission([
    Permission.CanDoAudits,
    Permission.CanAssignAudits,
    Permission.CanManageAudits,
    Permission.CanScheduleAudits,
    Permission.CanViewAuditResults,
    Permission.CanManageAuditTemplates,
    Permission.CanManageBilling,
    Permission.CanViewCorrectiveActions,
    Permission.CanViewAnalytics,
    Permission.CanManageGeneralSetup,
    Permission.CanDoCorrectiveActions,
    Permission.CanViewIssues,
    Permission.CanManageIssueTypes,
  ]);

  return useMemo(() => {
    const canViewAuditsPages =
      canDoAudits || canAssignAudits || canManageAudits || canScheduleAudits;
    const canViewSetupPages = !isAuditeeOrObserver && !isAuditor;

    let appRoutes: RouteObject[] = [
      {
        path: routes.createIssue,
        element: <CreateIssuePublicPage />,
      },
      {
        path: routes.createIssueError,
        element: <CreateIssuePublicPageError />,
      },
      {
        path: routes.publicAuditReport,
        element: <PublicAuditReport />,
      },
      {
        path: routes.signUpCallback,
        element: <SignUpCallback />,
      },
      {
        path: routes.signin,
        element: (
          <NonProtectedRoute>
            <SignIn />
          </NonProtectedRoute>
        ),
      },
      {
        path: routes.adminSignIn,
        element: (
          <NonProtectedRoute>
            <SignIn adminSignIn />
          </NonProtectedRoute>
        ),
      },
      ...(appConfiguration.allowSignUp
        ? [
            {
              path: routes.signUp,
              element: (
                <NonProtectedRoute>
                  <SignUp />
                </NonProtectedRoute>
              ),
            },
          ]
        : []),
      {
        path: routes.resetPassword,
        element: (
          <NonProtectedRoute>
            <ResetPassword />
          </NonProtectedRoute>
        ),
      },
      {
        path: routes.setNewPassword,
        element: (
          <NonProtectedRoute>
            <SetNewPassword />
          </NonProtectedRoute>
        ),
      },
      {
        path: routes.changePassword,
        element: (
          <ProtectedRoute Layout={NavyBlueContainer} skipChangePasswordCheck>
            <ChangeDefaultPassword />
          </ProtectedRoute>
        ),
      },
      {
        path: routes.dashboard,
        element: (
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        ),
      },
      {
        path: routes.notifications,
        element: (
          <ProtectedRoute>
            <Notifications />
          </ProtectedRoute>
        ),
      },
      {
        path: routes.profile,
        element: (
          <ProtectedRoute>
            <EditProfile />
          </ProtectedRoute>
        ),
      },
      {
        path: routes.notFound,
        element: (
          <ProtectedRoute>
            <NotFound />
          </ProtectedRoute>
        ),
      },
      {
        path: routes.tagsItems,
        element: (
          <ProtectedRoute>
            <ItemsTags />
          </ProtectedRoute>
        ),
      },
      {
        path: routes.tagsAudits,
        element: (
          <ProtectedRoute>
            <AuditsTags />
          </ProtectedRoute>
        ),
      },
      {
        path: routes.tagsActions,
        element: (
          <ProtectedRoute>
            <ActionsTags />
          </ProtectedRoute>
        ),
      },
      ...(appConfiguration.allowSignUp
        ? [
            {
              path: routes.accounts,
              element: (
                <ProtectedRoute>
                  <Accounts />
                </ProtectedRoute>
              ),
            },
          ]
        : []),
      {
        path: routes.billing,
        element: <Navigate to={routes.subscription} replace />,
      },
      {
        path: routes.correctiveActions,
        element: <Navigate to={routes.pendingActions} replace />,
      },
      {
        path: routes.settings,
        element: <Navigate to={routes.generalSettings} replace />,
      },
      {
        path: routes.auditorPerformanceReport,
        element: (
          <Navigate to={routes.auditorPerformanceReportRealTime} replace />
        ),
      },
      {
        path: routes.audits,
        element: <Navigate to={routes.pendingAudits} replace />,
      },
      {
        path: routes.tags,
        element: <Navigate to={routes.tagsAudits} replace />,
      },
    ];

    if (canViewSetupPages) {
      appRoutes = [
        ...appRoutes,
        {
          path: routes.users.list,
          element: (
            <ProtectedRoute>
              <Users />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.users.jobTitles,
          element: (
            <ProtectedRoute>
              <JobTitles />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.users.userDetails,
          element: (
            <ProtectedRoute>
              <UserDetails />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.users.userGroups,
          element: (
            <ProtectedRoute>
              <UserGroups />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.users.userAccess,
          element: (
            <ProtectedRoute>
              <UserAccess />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.users.user,
          element: <Navigate to={routes.users.userDetails} replace />,
        },
        {
          path: routes.users.groupsList,
          element: (
            <ProtectedRoute>
              <UsersGroups />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.users.groupDetails,
          element: (
            <ProtectedRoute>
              <UserGroupDetails />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.users.groupAssignments,
          element: (
            <ProtectedRoute>
              <UserGroupAssignments />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.auditObjects.list,
          element: (
            <ProtectedRoute>
              <AuditObjects />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.auditObjects.auditObjectDetails,
          element: (
            <ProtectedRoute>
              <AuditObjectDetails />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.auditObjects.auditObjectGroups,
          element: (
            <ProtectedRoute>
              <AuditObjectGroups />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.auditObjects.auditObjectAttributes,
          element: (
            <ProtectedRoute>
              <AuditObjectAttributes />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.auditObjects.auditObjectAccess,
          element: (
            <ProtectedRoute>
              <AuditObjectAccess />
            </ProtectedRoute>
          ),
        },
        ...(canManageIssueTypes
          ? [
              {
                path: routes.auditObjects.auditObjectQRCodes,
                element: (
                  <ProtectedRoute>
                    <AuditObjectQrCodes />
                  </ProtectedRoute>
                ),
              },
            ]
          : []),
        {
          path: routes.auditObjects.auditObject,
          element: (
            <Navigate to={routes.auditObjects.auditObjectDetails} replace />
          ),
        },
        {
          path: routes.auditObjects.groupsList,
          element: (
            <ProtectedRoute>
              <AuditObjectsGroups />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.auditObjects.groupDetails,
          element: (
            <ProtectedRoute>
              <AuditObjectGroupDetails />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.auditObjects.groupAssignments,
          element: (
            <ProtectedRoute>
              <AuditObjectGroupAssignments />
            </ProtectedRoute>
          ),
        },
        ...(canManageIssueTypes
          ? [
              {
                path: routes.auditObjects.groupQRCodes,
                element: (
                  <ProtectedRoute>
                    <AuditObjectGroupQrCodes />
                  </ProtectedRoute>
                ),
              },
            ]
          : []),
        {
          path: routes.auditObjects.attributeAssignments,
          element: (
            <ProtectedRoute>
              <AttributeAssignments />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.auditObjects.attributeDetails,
          element: (
            <ProtectedRoute>
              <AttributeDetails />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.auditObjects.attributesList,
          element: (
            <ProtectedRoute>
              <Attributes />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.roles,
          element: (
            <ProtectedRoute>
              <Roles />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.scores,
          element: (
            <ProtectedRoute>
              <Scores />
            </ProtectedRoute>
          ),
        },
      ];
    }

    if (canViewAuditsPages) {
      appRoutes = [
        ...appRoutes,
        {
          path: routes.schedules,
          element: (
            <ProtectedRoute>
              <Schedules />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.scheduleDetails,
          element: (
            <ProtectedRoute>
              <Schedules />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.schedulesLegacy,
          element: <Navigate to={routes.schedules} replace />,
        },
        {
          path: routes.advancedSchedules,
          element: (
            <ProtectedRoute>
              <SchedulePlans />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.advancedScheduleDetails,
          element: (
            <ProtectedRoute>
              <SchedulePlanDetails />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.advancedSchedulePeriodDetails,
          element: (
            <ProtectedRoute>
              <Suspense fallback={<Loader />}>
                <SchedulePlanPeriodDetails />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: routes.pendingAudit,
          element: (
            <ProtectedRoute>
              <PendingAudits />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.myAudits,
          element: (
            <ProtectedRoute>
              <PendingAudits />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.pendingAudits,
          element: (
            <ProtectedRoute>
              <PendingAudits />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.myAudit,
          element: <NavigateWithId to={routes.pendingAudit} />,
        },
      ];
    }

    if (canViewAuditsPages || canViewAuditsResults) {
      appRoutes = [
        ...appRoutes,
        {
          path: routes.completedAudits,
          element: (
            <ProtectedRoute>
              <CompletedAudits />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.completedAudit,
          element: (
            <ProtectedRoute>
              <CompletedAudits />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.auditReport,
          element: <NavigateWithId to={routes.completedAudit} />,
        },
        {
          path: routes.expiredAudits,
          element: (
            <ProtectedRoute>
              <ExpiredAudits />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.expiredAudit,
          element: (
            <ProtectedRoute>
              <ExpiredAudits />
            </ProtectedRoute>
          ),
        },
      ];
    }

    if (canViewCorrectiveActions || canDoCorrectiveActions) {
      appRoutes = [
        ...appRoutes,
        {
          path: routes.pendingActions,
          element: (
            <ProtectedRoute>
              <PendingActions />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.pendingAction,
          element: (
            <ProtectedRoute>
              <PendingActions />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.myActions,
          element: (
            <ProtectedRoute>
              <MyActions />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.myAction,
          element: (
            <ProtectedRoute>
              <MyActions />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.completedActions,
          element: (
            <ProtectedRoute>
              <CompletedActions />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.completedAction,
          element: (
            <ProtectedRoute>
              <CompletedActions />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.expiredActions,
          element: (
            <ProtectedRoute>
              <ExpiredActions />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.expiredAction,
          element: (
            <ProtectedRoute>
              <ExpiredActions />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.actionDetails,
          element: <NavigateWithId to={routes.pendingAction} />,
        },
      ];
    }

    if (canManageTemplates) {
      appRoutes = [
        ...appRoutes,
        {
          path: routes.auditTemplates,
          element: (
            <ProtectedRoute>
              <Suspense fallback={<Loader />}>
                <AuditTemplates />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: routes.builderDraft,
          element: (
            <ProtectedRoute>
              <Suspense fallback={<Loader />}>
                <AuditTemplateBuilder />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: routes.builderPublished,
          element: (
            <ProtectedRoute>
              <Suspense fallback={<Loader />}>
                <AuditTemplateBuilder />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: routes.builderNewDraft,
          element: (
            <ProtectedRoute>
              <Suspense fallback={<Loader />}>
                <AuditTemplateBuilder />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: routes.actionTemplates,
          element: (
            <ProtectedRoute>
              <Suspense fallback={<Loader />}>
                <ActionTemplates />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: routes.library,
          element: <Navigate to={routes.auditTemplates} replace />,
        },
      ];
    }

    if (canViewAnalytics) {
      appRoutes = [
        ...appRoutes,
        {
          path: routes.summaryReport,
          element: (
            <ProtectedRoute>
              <Suspense fallback={<Loader />}>
                <SummaryReport />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: routes.auditPerformanceReport,
          element: (
            <ProtectedRoute>
              <Suspense fallback={<Loader />}>
                <AuditPerformanceReport />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: routes.customReport,
          element: (
            <ProtectedRoute>
              <Suspense fallback={<Loader />}>
                <CustomReport />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: routes.itemAnalysisReport,
          element: (
            <ProtectedRoute>
              <Suspense fallback={<Loader />}>
                <ItemAnalysisReport />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: routes.auditorPerformanceReportHistorical,
          element: (
            <ProtectedRoute>
              <Suspense fallback={<Loader />}>
                <HistoricalPerformanceReport
                  type={PerformanceReportType.Auditor}
                />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: routes.auditorPerformanceReportRealTime,
          element: (
            <ProtectedRoute>
              <Suspense fallback={<Loader />}>
                <RealTimePerformanceReport
                  type={PerformanceReportType.Auditor}
                />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: routes.auditObjectPerformanceReportHistorical,
          element: (
            <ProtectedRoute>
              <Suspense fallback={<Loader />}>
                <HistoricalPerformanceReport
                  type={PerformanceReportType.Object}
                />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: routes.auditObjectPerformanceReportRealTime,
          element: (
            <ProtectedRoute>
              <Suspense fallback={<Loader />}>
                <RealTimePerformanceReport
                  type={PerformanceReportType.Object}
                />
              </Suspense>
            </ProtectedRoute>
          ),
        },
      ];
    }

    if (canManageBilling) {
      appRoutes = [
        ...appRoutes,
        {
          path: routes.subscription,
          element: (
            <ProtectedRoute>
              <Subscription />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.invoices,
          element: (
            <ProtectedRoute>
              <Invoices />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.payment,
          element: (
            <ProtectedRoute>
              <Payment />
            </ProtectedRoute>
          ),
        },
      ];
    }

    if (canManageIssueTypes) {
      appRoutes = [
        ...appRoutes,
        {
          path: routes.issueTypes,
          element: (
            <ProtectedRoute>
              <IssueTypes />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.issueTypeDetails,
          element: (
            <ProtectedRoute>
              <IssueTypeRoot />
            </ProtectedRoute>
          ),
          children: [
            {
              index: true,
              element: <IssueTypeDetails />,
            },
            {
              path: 'qr-codes',
              element: <IssueTypeDetailsQrCodes />,
            },
          ],
        },
        {
          path: routes.createIssueType,
          element: (
            <ProtectedRoute>
              <IssueTypeDetails />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.issuesQrCodes,
          element: (
            <ProtectedRoute>
              <IssueTypeQrCodes />
            </ProtectedRoute>
          ),
        },
      ];
    }

    if (canViewIssues) {
      appRoutes = [
        ...appRoutes,
        {
          path: routes.pendingIssues,
          element: (
            <ProtectedRoute>
              <PendingIssues />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.pendingIssue,
          element: (
            <ProtectedRoute>
              <PendingIssues />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.myIssues,
          element: (
            <ProtectedRoute>
              <MyIssues />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.myIssue,
          element: (
            <ProtectedRoute>
              <MyIssues />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.archiveIssues,
          element: (
            <ProtectedRoute>
              <ResolvedIssues />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.archiveIssue,
          element: (
            <ProtectedRoute>
              <ResolvedIssues />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.issueDetails,
          element: <NavigateWithId to={routes.pendingIssue} />,
        },
      ];
    }

    if (canManageGeneralSetup) {
      appRoutes = [
        ...appRoutes,
        {
          path: routes.generalSettings,
          element: (
            <ProtectedRoute>
              <GeneralSettings />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.integration,
          element: (
            <ProtectedRoute>
              <Integrations />
            </ProtectedRoute>
          ),
        },
      ];
    }

    appRoutes = [
      ...appRoutes,
      {
        path: '*',
        element: (
          <Navigate
            to={!!user ? routes.notFound : routes.signin}
            state={{ referrer: location.pathname }}
            replace
          />
        ),
      },
    ];

    return appRoutes;
  }, [
    canDoAudits,
    canAssignAudits,
    canManageAudits,
    canScheduleAudits,
    canViewAuditsResults,
    canManageTemplates,
    canManageBilling,
    canViewCorrectiveActions,
    canViewAnalytics,
    canManageGeneralSetup,
    canDoCorrectiveActions,
  ]);
}

export function useAmbassadorParams() {
  const [searchParams] = useSearchParams();
  const [ambassadorParams, setAmbassadorParams] = useState<AmbassadorParams>();

  useEffect(() => {
    function getAmbassadorParamsFromStorage() {
      const mbsy = localStorage.getItem('mbsy');
      const campaignId = localStorage.getItem('campaignId');
      const mbsyExpString = localStorage.getItem('mbsy_exp');

      if (mbsy && campaignId) {
        if (mbsyExpString) {
          const expirationDate = dayjs.utc(mbsyExpString);

          if (
            expirationDate.isValid() &&
            dayjs().utc().isBefore(expirationDate)
          ) {
            return { mbsy, campaignId };
          } else {
            localStorage.removeItem('mbsy');
            localStorage.removeItem('campaignId');
            localStorage.removeItem('mbsy_exp');
            return null;
          }
        } else {
          return { mbsy, campaignId };
        }
      }

      return null;
    }

    function saveAmbassadorParamsToStorage(
      mbsy: string,
      campaignId: string,
      mbsyExpString: string | undefined
    ) {
      localStorage.setItem('mbsy', mbsy);
      localStorage.setItem('campaignId', campaignId);

      if (mbsyExpString) {
        const expirationDate = dayjs.utc(
          mbsyExpString,
          'ddd, D MMM YYYY HH:mm:ss [GMT]',
          true
        );

        if (expirationDate.isValid()) {
          localStorage.setItem('mbsy_exp', expirationDate.toISOString());
        } else {
          Logger.captureException(
            new UnexpectedError(
              `Invalid mbsy_exp date format: ${mbsyExpString}`
            )
          );
        }
      }
    }

    async function getAndSetAmbassadorParams() {
      const mbsyParam = searchParams.get('mbsy');
      const campaignIdParam = searchParams.get('campaignid');
      const mbsyExpParam = searchParams.get('mbsy_exp');

      const mbsyExpString = mbsyExpParam
        ? decodeURIComponent(mbsyExpParam)
        : undefined;

      let mbsy = mbsyParam;
      let campaignId = campaignIdParam;

      if (mbsy && campaignId) {
        saveAmbassadorParamsToStorage(mbsy, campaignId, mbsyExpString);
      } else {
        const storedParams = getAmbassadorParamsFromStorage();
        if (storedParams) {
          mbsy = storedParams.mbsy;
          campaignId = storedParams.campaignId;
        }
      }

      if (mbsy && campaignId) {
        setAmbassadorParams({ mbsy, campaignId });
      }
    }

    getAndSetAmbassadorParams();
  }, [
    searchParams.get('mbsy'),
    searchParams.get('campaignid'),
    searchParams.get('mbsy_exp'),
  ]);

  return ambassadorParams;
}
