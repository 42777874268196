import React from 'react';
import { useIntl } from 'react-intl';

import { Section, Description, Container, Button } from './styled';

import PageHeader from '../../shared/ant/PageHeader';
import UploadLogo from './UploadLogo/UploadLogo';
import UploadIcon from './UploadIcon/UploadIcon';
import UpdateCompanyForm from '@components/account/AuthForms/SignUp/UpdateCompanyForm';

const General: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { formatMessage } = useIntl();

  return (
    <PageHeader title={formatMessage({ id: 'General' })}>
      <Container>
        <Description>
          {formatMessage({ id: 'SetUpYourCompanyAndAdditionalNeeds' })}
        </Description>
        <Section>
          <h3>{formatMessage({ id: 'Logo&Icon' })}</h3>
          <UploadLogo />
          <UploadIcon />
        </Section>
        <Section>
          <h3>{formatMessage({ id: 'Details' })}</h3>
          <UpdateCompanyForm>
            {({ loading, form }) => (
              <Button
                type="primary"
                loading={loading}
                onClick={() => form.submit()}
              >
                {formatMessage({ id: 'Update' })}
              </Button>
            )}
          </UpdateCompanyForm>
        </Section>
      </Container>
    </PageHeader>
  );
};

export default General;
