import React, { useCallback, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { useAppDispatch } from '@hooks';
import { accountActions } from '@store';
import { routes } from '@config';
import { notification } from '@utils';

import Loader from '@repo/shared/components/Loader';

interface IProps {}

const SignUpCallback: React.FC<React.PropsWithChildren<IProps>> = () => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const token = searchParams.get('authToken');

  const redirectToSignInWithError = useCallback(() => {
    navigate(routes.signin);

    notification.error({
      message: formatMessage({ id: 'ErrorWhileSigninIn' }),
    });
  }, []);

  useEffect(() => {
    async function signIn(tkn: string) {
      const resultAction = await dispatch(
        accountActions.signInWithCustomToken(tkn)
      );

      if (accountActions.signInWithCustomToken.fulfilled.match(resultAction)) {
        navigate(routes.dashboard, { state: { onboarding: true } });
      } else {
        redirectToSignInWithError();
      }
    }

    if (token) {
      signIn(token);
    } else {
      redirectToSignInWithError();
    }
  }, [token]);

  return <Loader />;
};

export default SignUpCallback;
