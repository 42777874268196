import React from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Divider, PasswordLabel, SignInPasswordFormItem } from './styled';
import { AnalyticsEvent } from '@repo/shared/enums';
import { accountActions, accountSelectors } from '@store';
import { useAppDispatch } from '@hooks';
import { notification } from '@utils';
import { routes, e2eTestElements } from '@config';
import { Logger } from '@repo/shared/services';

import Form from '../../shared/ant/Form';
import { Input, InputPassword } from '../../shared/ant/Input';
import Button from '../../shared/ant/Button';
import SocialAuthButton from '../SocialAuthButton/SocialAuthButton';
import OneColAuthWrapper from './OneColAuthWrapper';

interface IProps {
  adminSignIn?: boolean;
}

const SignIn: React.FC<React.PropsWithChildren<IProps>> = ({ adminSignIn }) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const appConfiguration = useSelector(accountSelectors.getAppConfiguration);
  const loading = useSelector(accountSelectors.getAuthFormLoading);

  return (
    <OneColAuthWrapper
      title={formatMessage({ id: 'SignInToYourAccount' })}
      footerAction={
        adminSignIn || !appConfiguration?.allowSignUp
          ? undefined
          : () => navigate(routes.signUp)
      }
      footerText={formatMessage({
        id: 'DontHaveAccountSignUp',
      })}
    >
      <Form
        name="sign-in-form"
        layout="vertical"
        onFinish={async (values: any) => {
          dispatch(accountActions.setAuthFormLoading(true));

          const action = adminSignIn
            ? accountActions.signInAdmin
            : accountActions.signIn;

          const resultAction = await dispatch(
            action({ email: values.email, password: values.password })
          );

          if (!adminSignIn && action.fulfilled.match(resultAction)) {
            dispatch(accountActions.sendAnalyticsEvent(AnalyticsEvent.SignIn));
          }

          if (action.rejected.match(resultAction)) {
            notification.error({
              message: formatMessage({ id: 'Error' }),
              description: resultAction.payload,
            });

            dispatch(accountActions.setAuthFormLoading(false));
          } else {
            Logger.setUser({
              email: values.email,
            });
          }
        }}
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: formatMessage({ id: 'PleaseEnterYourEmail' }),
            },
            {
              type: 'email',
              message: formatMessage({ id: 'PleaseEnterValidEmail' }),
            },
          ]}
        >
          <Input
            disabled={loading}
            placeholder={formatMessage({ id: 'Email' })}
            data-e2e={e2eTestElements.account.signInForm.emailInput}
          />
        </Form.Item>
        <SignInPasswordFormItem>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: formatMessage({ id: 'PleaseEnterYourPassword' }),
              },
            ]}
          >
            <InputPassword
              disabled={loading}
              data-e2e={e2eTestElements.account.signInForm.passwordInput}
              placeholder={formatMessage({ id: 'Password' })}
            />
          </Form.Item>
          {!adminSignIn && (
            <PasswordLabel>
              <span />
              <Link tabIndex={-1} to={routes.resetPassword}>
                {formatMessage({ id: 'ForgotYourPassword' })}
              </Link>
            </PasswordLabel>
          )}
        </SignInPasswordFormItem>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: '100%', marginTop: '16px' }}
            loading={loading}
            data-e2e={e2eTestElements.account.signInForm.submitBtn}
          >
            {formatMessage({ id: 'SignIn' })}
          </Button>
        </Form.Item>
        {!adminSignIn && (
          <>
            <Divider orientation="center" plain>
              {formatMessage({ id: 'or' })}
            </Divider>
            <SocialAuthButton
              onBtnClick={() => {
                dispatch(accountActions.setAuthFormLoading(true));
              }}
              onError={() => {
                dispatch(accountActions.setAuthFormLoading(false));
              }}
            />
          </>
        )}
      </Form>
    </OneColAuthWrapper>
  );
};

export default SignIn;
